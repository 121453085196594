.gallerycontainer {
  width: 100%;
  margin-bottom: 75px;
  .gallerytitle {
    text-align: center;
    margin-top: 75px;
    margin-bottom: 50px;
    color: #086d44;
    font-family: "Poppins", Sans-serif;
    font-weight: 600;
    font-size: 40px;
    @media (max-width: 768px) {
      font-size: 28px;
    }
  }
  .gallerycarousel {
    width: 100%;
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .center {
      .slick-slider {
        width: 100%;
        .slick-next::before,
        .slick-prev::before {
          font-family: "slick";
          font-size: 30px;
        }
        .slick-list {
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
        }
        .slick-track {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
        }

        .slick-dots {
          position: absolute; /* Make dots absolute for positioning within slides */
          bottom: 10px; /* Adjust vertical positioning */
          left: 50%; /* Center dots horizontally */
          transform: translateX(-50%); /* Center dots precisely */
          z-index: 1; /* Ensure dots are above image content */
        }
        .slick-next {
          right: 25px;
        }
        .slick-prev {
          z-index: 1;
          left: 25px;
        }
        .slick-dots {
          li {
            margin: 0;
          }
        }
      }
      @media (max-width: 768px) {
        width: 90%;
      }
    }
  }
  .promopointg {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    @media (max-width: 768px) {
      width: 100%;
    }

    .poinitem {
      display: flex;
      width: 60%;
      gap: 50px;
      font-size: 18px;
      margin-bottom: 25px;
      @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
        gap: 0px;
        margin-left: 15px;
        font-size: 22px;
      }
    }
  }
  .gallerybutton {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    .galleryleft {
      width: 60%;
      @media (max-width: 768px) {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .button {
        font-family: "Poppins", Sans-serif;
        font-weight: 600;
        color: white;
        background-color: #6f8e6a;
        cursor: pointer;
        border: none;
        padding: 12px 15px;
        @media (max-width: 768px) {
          padding: 12px 60px;
        }
      }
    }
  }
  .divider {
    width: 80%;
  }
}
