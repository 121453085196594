.hero-container {
  width: 100%;
  height: 100%;
  .heroimage {
    width: 100%;
    height: 100h;
    object-fit: cover;
    @media (max-width: 768px) {
      height: 80vh;
    }
  }
}
