.aboutcontainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .aboutcenter {
    width: 70%;
    @media (max-width: 768px) {
      width: 85%;
    }

    .aboutlogo {
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;
      .logo {
        width: 300px;
        @media (max-width: 768px) {
          display: none;
        }
      }
      .logodes {
        margin-left: 22px;
        color: #749a8a;
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
    .logodes {
      display: none;
      @media (max-width: 768px) {
        display: block;
        color: #1d7e10;
        font-size: 16px;
        font-family: "Roboto", Sans-serif;
        font-weight: 600;
        color: #086d44;
      }
    }
    .logoprice {
      display: none;
      @media (max-width: 768px) {
        display: block;
        font-size: 24px;
        color: #878787;
        font-family: "Roboto", Sans-serif;
        font-weight: 600;
        margin-bottom: 15px;
      }
    }
    .abouttitle {
      color: #086d44;
      font-family: "Roboto", Sans-serif;
      font-weight: 600;
      font-size: 25px;
      margin-bottom: 25px;
    }
    .aboutsubtitle {
      margin-bottom: 25px;
      .read-more-button {
        margin-top: 10px;
        cursor: pointer;
      }
    }
  }
}
