.surroundingcontainer {
  width: 100%;
  .surroundingtitle {
    margin-bottom: 15px;
    text-align: center;
    color: #086d44;
    font-family: "Roboto", Sans-serif;
    font-size: 43px;
    font-weight: 700;
  }
  .surroundingimgtitle {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    .surroundingimg {
      width: 40%;
      @media (max-width: 768px) {
        width: 90%;
      }
    }
  }
  .surroundingcarousel {
    width: 100%;
    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .center {
      .slick-slider {
        width: 100%;
        .slick-next::before,
        .slick-prev::before {
          font-family: "slick";
          font-size: 30px;
        }
        .slick-list {
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
        }
        .slick-track {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
        }

        .slick-dots {
          position: absolute; /* Make dots absolute for positioning within slides */
          bottom: 10px; /* Adjust vertical positioning */
          left: 50%; /* Center dots horizontally */
          transform: translateX(-50%); /* Center dots precisely */
          z-index: 1; /* Ensure dots are above image content */
        }
        .slick-next {
          right: 25px;
        }
        .slick-prev {
          z-index: 1;
          left: 25px;
        }
        .slick-dots {
          li {
            margin: 0;
          }
        }
      }
      @media (max-width: 768px) {
        width: 90%;
      }
    }
  }
  .divider {
    width: 80%;
  }
}
