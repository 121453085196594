.card {
  border-radius: 20px 20px 20px 20px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  width: 400px;
  margin-bottom: 25px;
  @media (max-width: 768px) {
    width: 95%;
    margin-bottom: 25px;
    margin-right: 0;
  }
  .slick-dots {
    bottom: 15px;
    li {
      margin: 0;
    }
  }
  .slick-next {
    right: 25px;
  }
  .slick-prev {
    z-index: 1;
    left: 25px;
  }
  .imgcarousel {
    border-radius: 20px 20px 0px 0px;
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  .card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .cardtitle {
      font-family: "Amiko", Sans-serif;
      font-size: 30px;
      font-weight: 600;
      margin-top: 10px;
      margin-bottom: 10px;
      color: #086d44;
    }
    .title {
      font-family: "Amiko", Sans-serif;
      font-size: 17px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .harga {
      font-family: "Amiko", Sans-serif;
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .card-price {
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: #086d44;
      color: #ffffff;
      font-family: "Amiko", Sans-serif;
      font-size: 25px;
      font-weight: 600;
      width: 100%;
      text-align: center;
    }
    .card-icon {
      padding: 20px 0px 20px 0px;
      font-size: 20px;
      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
    .card-button {
      text-align: center;
      width: 100%;
      margin-bottom: 15px;
      .button {
        font-size: 16px;
        font-weight: 400;

        line-height: 1em;
        letter-spacing: 0px;
        fill: #ffffff;
        color: #ffffff;
        background-color: #086d44;
        border-style: solid;
        border-width: 1px 1px 1px 1px;
        border-color: #086d44;
        border-radius: 100px 100px 100px 100px;
        padding: 16px 85px;
        display: inline-block;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;
      }
    }
  }
}
