.promocontainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .promocenter {
    text-align: center;
    width: 60%;

    @media (max-width: 768px) {
      width: 100%;
    }
    .promoimg {
      width: 60%;
      box-shadow: 0 0 10px 0;
      border-radius: 5px;
      @media (max-width: 768px) {
        width: 90%;
      }
    }
  }
  .divider {
    width: 75%;
  }
  .promopoint {
    width: 70%;
    margin-top: 10px;
    @media (max-width: 768px) {
      width: 80%;
    }
    .pointitle {
      color: #086d44;
      font-family: "Roboto", Sans-serif;
      font-weight: 600;
      font-size: 29px;
      margin-top: 10px;
      margin-bottom: 25px;
    }
    .poinitem {
      display: flex;
      width: 100%;
      gap: 50px;
      font-size: 18px;
      margin-bottom: 25px;
      @media (max-width: 768px) {
        flex-direction: column;
        gap: 0px;
        margin-left: 15px;
        font-size: 22px;
      }
    }
  }
}
