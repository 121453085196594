.lokasicontainer {
  margin-top: 75px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .lokasimap {
    width: 100%;
    text-align: center;
    margin-bottom: -50px;
    @media (max-width: 768px) {
      width: 90%;
    }
  }
  .lokasibutton {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .center {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 40%;
      background-color: #333333b0;
      @media (max-width: 768px) {
        width: 90%;
      }
      .button {
        cursor: pointer;
        border-radius: 5px;
        padding: 12px 20px;
        border: none;
        color: white;
        background-color: #61ce70;
      }
    }
  }
  .lokasititle {
    margin-top: 25px;
    width: 60%;
    color: #086d44;
    font-family: "Poppins", Sans-serif;
    font-weight: 600;
    font-size: 40px;
    @media (max-width: 768px) {
      width: 90%;
    }
  }
  .listlokasi {
    width: 60%;
    @media (max-width: 768px) {
      width: 90%;
    }
    li {
      padding: 5px;
    }
  }
}
